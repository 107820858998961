import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";

export default function UpdateProfile() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { currentUser, updatePassword, updateEmail } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    function handleSubmit(e) {
        e.preventDefault()
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("パスワードが一致しません。")
        }

        const promises = []
        setLoading(true)
        setError("")

        if (emailRef.current.value !== currentUser.email) {
            promises.push(updateEmail(emailRef.current.value))
        }
        if (passwordRef.current.value) {
            promises.push(updatePassword(passwordRef.current.value))
        }

        Promise.all(promises).then(() => {
            history.push("/data")
        }).catch(() => {
            setError("更新に失敗しました。")
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <>
            <Card>
                <h2 className="text-center mb-4">プロフィール編集</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                        <Form.Label>メール</Form.Label>
                        <Form.Control type="email" ref={emailRef} required defaultValue={currentUser.email} />
                    </Form.Group>
                    <Form.Group id="password">
                        <Form.Label>パスワード</Form.Label>
                        <Form.Control type="password" ref={passwordRef} placeholder="空白のままにすると、パスワードは変更しません。" />
                    </Form.Group>
                    <Form.Group id="password-confirm">
                        <Form.Label>パスワード再入力</Form.Label>
                        <Form.Control type="password" ref={passwordConfirmRef} placeholder="空白のままにすると、パスワードは変更しません。" />
                    </Form.Group>
                    <Button disable={loading} className="w-100" type="submit">
                        変更
                    </Button>
                </Form>
            </Card>
            <div className="w-100 text-center mt-2">
                <Link to="/data">キャンセル</Link> 
            </div>
        </>
    )
}