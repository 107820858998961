import React, { useState } from "react";
import { Card, Button, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";

export default function Dashboard() {
    const [error, setError] = useState("")
    const { currentUser, logout } = useAuth()
    const history = useHistory()

    async function handleLogout() {
        setError("")

        try {
            await logout()
            history.push("/data/login")
        } catch {
            setError("ログアウトに失敗しました。")
        }

    }

    return (
        <>
            <Card>
                <Card.Body>
                    <h5 className="text-center mb-4">ログインした人だけが見られるページ</h5>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <strong>メール：</strong>{currentUser.email}
                    <Link to="data/update-profile" className="btn btn-primary w-100 mt-3">
                        プロフィール編集
                    </Link>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                <Button variant="link" onClick={handleLogout}>ログアウト</Button>
            </div>
            <section className="home__section">
      <div className="home__container">
        <div className="home__title">
          <span className="home__intro">著作権に注意</span>
        </div>
        <div className="home__desc">
          <span className="home__txt__color">
              <p>
                各々の論文には「著作権」というものがあります。無断転載や複製などはご遠慮ください。
              </p>
            </span>
        </div>
      </div>
    </section>
        </>
    )
}