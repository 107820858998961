import React from "react";
import "./Project.css";

function Project() {
  return (
    <section className="project__section">
      <div className="project__container">
        <div className="project__title">
          <a href="http://www.ryukyu.bun.kyoto-u.ac.jp/langlogic/" target="_blank" rel="noreferrer" class="plain">
            Language & Logic
          </a>
        </div>
        <div className="project__desc">
          Group 36 in the research project "Towards a Center of Excellence for the Study of Humanities in the Age of Globalization" at Kyoto University.
        </div>
      </div>
      <div className="project__container">
        <div className="project__title">
          <a href="http://www.ryukyu.bun.kyoto-u.ac.jp/bc/" target="_blank" rel="noreferrer" class="plain">
            証拠と推論：新たなパラダイムの構築に向けて
          </a>
        </div>
        <div className="project__desc">
          頭脳循環を活性化する若手研究者海外派遣プログラム。
        </div>
      </div>
    </section>
  );
}

export default Project;