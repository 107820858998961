import React from "react";

class Detail extends React.Component{
        componentDidMount() {
            const { location, history } = this.props;
            if (location.state === undefined ) {
                history.push("/");
            }
        }
    render(){
        const {location } = this.props;
        if (location.state) {
            return (
                <div>
                    <h1>{location.state.author}</h1>
                    <h2>{location.state.title}</h2>
                    <p>{location.state.abstract}</p>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default Detail;