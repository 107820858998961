import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
    const emailRef = useRef()
    const { resetPassword } = useAuth()
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setMessage("")
            setError("")
            setLoading(true)
            await resetPassword(emailRef.current.value)
            setMessage("パスワードを初期化するために、メールを発送しました。")
        } catch {
            setError("パスワードの初期化に失敗しました。")
        }
        setLoading(false)
    }

    return (
        <>
            <Card>
                <h2 className="text-center mb-4">パスワード初期化</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                {message && <Alert variant="success">{message}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" ref={emailRef} required />
                    </Form.Group>
                    <Button disable={loading} className="w-100" type="submit">
                        パスワード初期化
                    </Button>
                </Form>
                <div className="w-100 text-center mt-3">
                    <Link to="/data/login">ログイン</Link>
                </div>

            </Card>
            {/* <div className="w-100 text-center mt-2">
                アカウントが必要ですか？<Link to="/data/signup">アカウント作成</Link>
            </div> */}
        </>
    )
}