import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import "./Login.css";

export default function Login() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const { login } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError('')
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
            history.push("/data")
        } catch {
            setError("ログインに失敗しました。")
        }
        setLoading(false)
    }

    return (
        <>
        <section className="login__box">
            <div className="login__box__card">
                <Card id="login__card">
                    <h4 className="text-center mb-4 login__card__title">ログイン</h4>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit} id="login__form">
                        <Form.Group id="email">
                            <div className="form__text__mail">
                                <Form.Label>
                                    メール
                                </Form.Label>
                            </div>
                            <div>
                                <Form.Control type="email" ref={emailRef} required />
                            </div>
                        </Form.Group>
                        <Form.Group id="password">
                            <Form.Label>パスワード</Form.Label>
                            <Form.Control type="password" ref={passwordRef} required />
                        </Form.Group>
                        <Button disable={loading} className="w-100" type="submit">
                            ログイン
                        </Button>
                    </Form>
                    <div className="w-100 text-center mt-3 login__forgot__pswd">
                        <Link to="/data/forgot-password">パスワードをお忘れの方</Link>
                    </div>
                </Card>
            </div>
                {/* <div className="w-100 text-center mt-2">
                    アカウントが必要ですか？<Link to="/data/signup">アカウント作成</Link>
                </div> */}
        </section>
        </>
    )
}