import React from "react";
import "./Home.css";

function Home() {
  return (
    <section className="home__section">
      <div className="home__container">
        <div className="home__title">
          <span className="home__intro">このサイトについて</span>
        </div>
        <div className="home__desc">
          <span className="home__txt__color">
              <p>
                このサイトは、京都大学言語学研究室で運営しています。「プロジェクト」には過去のプロジェクトを掲載しています。「卒論・修論閲覧」では、卒業生・修了生の論文が閲覧できます（論文の閲覧にはログインが必要です（メンテナンス中です❕））。最終更新日は、2021年6月9日です。お問い合わせは、<a href="http://www.bun.kyoto-u.ac.jp/linguistics/lin-members/" target="_blank" rel="noreferrer">教務補佐員</a>までご連絡ください。
              </p>
            </span>
        </div>
      </div>
      <div className="home__container">
        <div className="home__lingbun">
          <a href="https://www.bun.kyoto-u.ac.jp/linguistics/lin-top_page/" target="_blank" rel="noreferrer" class="plain">
            <span className="home__lingbun">言語学専修ホームページ</span>
          </a>
        </div>
        <div className="home__desc">
          <span  className="home__txt__color">
            研究室からのお知らせや、研究室名簿・時間割・研究会・勉強会などの情報は、こちらでご覧いただけます。
          </span>
        </div>
      </div>
    </section>
  );
}

export default Home;