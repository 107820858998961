import React from "react";
// import axios from "axios";
// import Paper from "../components/Paper";
// import "./Data.css";
// import Signup from "../components/Signup";
import "bootstrap/dist/css/bootstrap.css";
import { Container } from "react-bootstrap";
import { AuthProvider } from "../contexts/AuthContext";
import { HashRouter, Switch, Route } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import Login from "../components/Login";
import PrivateRoute from "../components/PrivateRoute";
import ForgotPassword from "../components/ForgotPassword";
import UpdateProfile from "../components/UpdateProfile";

function Data() {
  return (

      <Container className="d-flex align-itmes-center justify-content-center" style={{ midHeight: "100vh" }}>
        <div className="w-100" style={{  maxWidth: "400px" }}>
          <HashRouter>
            <AuthProvider>
              <Switch>
                <PrivateRoute exact path="/data" component={Dashboard} />
                <PrivateRoute path="/data/update-profile" component={UpdateProfile} />
                {/* <Route path="/data/signup" component={Signup} /> */}
                <Route path="/data/login" component={Login} />
                <Route path="/data/forgot-password" component={ForgotPassword} />
              </Switch>
            </AuthProvider>
          </HashRouter>
        </div>
      </Container>

  )
}

// class Data extends React.Component {
//     state = {
//       isLoading: true,
//       papers: []
//     };
  
//     getMovies = async () => {
//       const {
//         data: {
//           data: { papers }
//         }
//       } = await axios.get("/lists.json");
//       this.setState({ papers, isLoading: false });
//     };
  
//     componentDidMount() {
//       this.getMovies();
//     }
  
//     render() {
//       const { isLoading, papers } = this.state;
//       return (
//         <section className="container">
//           {isLoading ? (
//             <div className="loader">
//               <span className="loader__text">Loading...</span>
//             </div>
//             ) : (
//               <div className="papers">
//                 {papers.map(paper => (
//                   <Paper
//                     id={paper.id}
//                     title={paper.title}
//                     author={paper.author}
//                     year={paper.year}
//                     abstract={paper.abstract}
//                     languages={paper.languages}
//                 />
//                 ))}
//               </div>
//             )}
//         </section>
//       );
//     }
//   }
  
export default Data;