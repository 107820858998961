import React from "react";
import { Link } from "react-router-dom";
import "./Navigation.css";

function Navigation() {
    return (
        <div className="nav">
            <div className="nav__link">
                <Link to="/">Home</Link>
            </div>
            <div className="nav__link">
                <Link to="/project">プロジェクト</Link>
            </div>
            <div className="nav__link">
                <Link to="/data">卒論・修論閲覧</Link>
            </div>
        </div>
    );
}

export default Navigation;